<template>
  <div class="home">
    <h4 class="fw-bold mb-4 d-flex page-title">
      <span class="mr-auto">
        {{$trans('Check-In')}}
      </span>
      <button @click="formFilter = !formFilter" class="btn btn-primary btn-sm">
        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" stroke-width="2" fill="none"
             stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1 mr-1">
          <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
        </svg>
        {{ $trans('Filter') }}
      </button>
    </h4>
    <div v-if="formFilter" class="card mb-3">
      <div class="card-body">
        <div class="d-flex">
          <select @change="index_reports" style="width: 200px;" v-model="filterQuery.by" class="form-control mb-3 mt-3">
            <option value="month">{{$trans('This Month')}}</option>
            <option value="last_month">{{$trans('Last Month')}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table v-if="reports && reports.length" class="table table-striped">
        <thead>
        <tr>
          <th>Name</th>
          <th v-for="(day,k) in daysOfMonth" :key="k">{{ day }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(report,k) in reports" :key="k">
          <slot v-if="report.records && Object.keys( report.records ).length > 0">
            <td style="white-space: nowrap;">
              <span class="d-block"><strong>{{ report.name }} - {{report.id}}</strong></span>
              <hr class="mt-1 mb-1">
              <span class="d-block">Work: {{ report.dayWork }}/{{ daysInMonth }}</span>
              <span class="d-block">Delay: {{ report.delay }}</span>
            </td>
            <td v-for="(day,k) in daysOfMonth" :key="k">
              <span class="d-flex" v-if="report.records[day]" v-bind:class="{'text-danger': isError(report.records[day]['inOk'], report.records[day]['outOk'])}">
                <span>
                   <u v-for="(time,i) in report.records[day]['times']" :key="i" @click="index = time.index" class="d-block" :alt="time.title" :title="time.title">{{time.time }}</u>
                </span>
                <span class="ml-2 text-black" v-if="report.records[day]['random_check']">
                    <span class="d-block">Random Check-In:<br>
                      + Status: <span v-if="report.records[day]['random_check']['status']==1 && report.records[day]['random_check']['delay']< 30" class="text-success">OK</span>
                      <span class="text-danger" v-else>NO</span>
                    </span>
                    <span>+ Time: {{report.records[day]['random_check']['_time']}}</span>
                    <span class="d-block">+ Delay:
                      <vue-numeric
                              currency-symbol-position="suffix"
                              currency="min"
                                v-model="report.records[day]['random_check']['delay']"
                                v-bind:read-only="true"
                                :minus="true"
                                v-bind:precision="0">
                      </vue-numeric>
                    </span>
                </span>
              </span>
              <slot v-else>-</slot>
            </td>
          </slot>
        </tr>
        </tbody>
      </table>
    </div>
    <gallery :options="options" :images="images" :index="index" @close="index = null"></gallery>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "CheckIn",
  data: function () {
    return {
      formFilter:false,
      report_data: null,
      filterQuery: {
        by: 'month'
      },
      images: [],
      index: null,
      options:{

      }
    }
  },
  methods: {
    index_reports: function () {
      this.report_data = null
      this.images = []
      axios({url: this.$root.$data.api_url + '/api/checkin', params: this.filterQuery, method: 'GET'})
          .then(resp => {
            this.report_data = resp.data;
          })
          .catch(err => {
            console.log(err)
          })
    },
    isError: function (inOk, outOk) {
      if (inOk && outOk) {
        return false
      }
      return true
    }
  },
  created() {
    this.index_reports()
  },
  computed: {
    daysInMonth: function () {
      if (this.report_data && this.report_data.daysInMonth) {
        return this.report_data.daysInMonth
      }
      return 0;
    },
    daysOfMonth: function () {
      var daysOfMonth = []
      for (let i = 1; i <= this.daysInMonth; i++) {
        var day = i;
        if (i < 10) {
          day = '0' + i
        }
        daysOfMonth.push(day)
      }
      return daysOfMonth
    },
    reports: function () {
      var data = []
      if (this.report_data && this.report_data.reports) {
        let reports = this.report_data.reports
        var index = 0
        reports.forEach((el) => {
          let row_records = {}
          let dayWork = 0
          let delay = 0
          let pics = []
          let records = el.records
          let daysOfMonth = this.daysOfMonth
          let random_check =null
          daysOfMonth.forEach((day) => {
            records.forEach((r) => {
              if (r.day == day) {

                // Check đi muộn
                var timeIn = r.in_out[0] || null
                var timeOut = r.in_out[r.in_out.length - 1] || null
                var timeInArr = timeIn.split(':')
                var timeoutArr = timeOut.split(':')
                var inOk = false
                var outOk = false
                if (timeInArr[0] == '08') {
                  var t1 = ['55', '56', '57', '58', '59']
                  if (t1.includes(timeInArr[1])) {
                    inOk = true
                  }
                }
                if (timeInArr[0] == '09') {
                  var t2 = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10']
                  if (t2.includes(timeInArr[1])) {
                    inOk = true
                  }
                }
                console.log(timeoutArr)
                if (timeoutArr[0] == '19') {
                  var t3 = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15']
                  if (t3.includes(timeoutArr[1])) {
                    outOk = true
                  }
                }

                if (inOk == false || outOk == false) {
                  delay = delay + 1
                }
                var times = []
                r.in_out.forEach((t) => {
                  times.push({
                    time: t,
                    index: index,
                    title:el.name+' - '+ t+', '+r.date,
                    timeIn:timeIn,
                    timeOut:timeOut
                  })
                  index = index + 1
                })
                r.pics.forEach((p,i_k) => {
                  var img = {
                    title:el.name+' ('+el.id+') - '+ r.in_out[i_k]+', '+r.date,
                    type: 'image/jpeg',
                    href: 'https://ql-api.seob4.com/' + p,
                  }
                  this.images.push(img)
                })

                random_check = r.random_check

                var r2 = {
                  in_out: r.in_out,
                  pics: r.pics,
                  day: r.day,
                  inOk: inOk,
                  outOk: outOk,
                  times:times,
                  random_check:random_check
                }

                row_records[day] = r2
                dayWork = dayWork + 1

              }

            })
          })

          var item = {
            name: el.name,
            id: el.id,
            records: row_records,
            dayWork: dayWork,
            delay: delay,
            pics: pics,
            random_check:random_check
          }
          data.push(item)
        })
      }
      return data
    },
    colWidth:function () {
      return (100/this.daysOfMonth)+'%';
    }

  }
}
</script>

<style scoped>

</style>